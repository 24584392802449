export default {
  ADD_FUEL_CODE_REQUEST: 'ADD_FUEL_CODE_REQUEST',
  DELETE_FUEL_CODE_REQUEST: 'DELETE_FUEL_CODE_REQUEST',
  ERROR_ADD_FUEL_CODE: 'ERROR_ADD_FUEL_CODE',
  ERROR_DELETE_FUEL_CODE: 'ERROR_DELETE_FUEL_CODE',
  ERROR_FUEL_CODE_REQUEST: 'ERROR_FUEL_CODE_REQUEST',
  ERROR_FUEL_CODES_REQUEST: 'ERROR_FUEL_CODES_REQUEST',
  ERROR_UPDATE_FUEL_CODE: 'ERROR_UPDATE_FUEL_CODE',
  GET_FUEL_CODE_REQUEST: 'GET_FUEL_CODE_REQUEST',
  GET_FUEL_CODES_REQUEST: 'GET_FUEL_CODES_REQUEST',
  GET_LATEST_FUEL_CODE_REQUEST: 'GET_LATEST_FUEL_CODE_REQUEST',
  RECEIVE_FUEL_CODE_REQUEST: 'RECEIVE_FUEL_CODE_REQUEST',
  RECEIVE_FUEL_CODES_REQUEST: 'RECEIVE_FUEL_CODES_REQUEST',
  RECEIVE_LATEST_FUEL_CODE_REQUEST: 'RECEIVE_LATEST_FUEL_CODE_REQUEST',
  SUCCESS_ADD_FUEL_CODE: 'SUCCESS_FUEL_CODE',
  SUCCESS_DELETE_FUEL_CODE: 'SUCCESS_DELETE_FUEL_CODE',
  SUCCESS_UPDATE_FUEL_CODE: 'SUCCESS_UPDATE_FUEL_CODE',
  UPDATE_FUEL_CODE_REQUEST: 'UPDATE_FUEL_CODE_REQUEST'
}
