export default {
  ADD_CREDIT_TRANSFER: 'ADD_CREDIT_TRANSFER',
  COMMIT_ERRORS: 'COMMIT_ERRORS',
  DELETE_CREDIT_TRANSFER: 'DELETE_CREDIT_TRANSFER',
  ERROR: 'ERROR',
  GET_APPROVED_CREDIT_TRANSFERS: 'GET_APPROVED_CREDIT_TRANSFERS',
  GET_CREDIT_TRANSFER: 'GET_CREDIT_TRANSFER',
  GET_CREDIT_TRANSFERS: 'GET_CREDIT_TRANSFERS',
  INVALIDATE_CREDIT_TRANSFER: 'INVALIDATE_CREDIT_TRANSFER',
  INVALIDATE_CREDIT_TRANSFERS: 'INVALIDATE_CREDIT_TRANSFERS',
  PROCESS_APPROVED_CREDIT_TRANSFERS: 'PROCESS_APPROVED_CREDIT_TRANSFERS',
  RECEIVE_APPROVED_CREDIT_TRANSFERS: 'RECEIVE_APPROVED_CREDIT_TRANSFERS',
  RECEIVE_CREDIT_TRANSFER: 'RECEIVE_CREDIT_TRANSFER',
  RECEIVE_CREDIT_TRANSFERS: 'RECEIVE_CREDIT_TRANSFERS',
  REQUEST: 'REQUEST',
  SUCCESS_ADD_CREDIT_TRANSFER: 'SUCCESS_ADD_CREDIT_TRANSFER',
  SUCCESS: 'SUCCESS',
  UPDATE_CREDIT_TRANSFER: 'UPDATE_CREDIT_TRANSFER'
}
