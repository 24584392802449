export default {
  ADD_COMMENT_TO_CREDIT_TRANSFER_REQUEST: 'ADD_COMMENT_TO_CREDIT_TRANSFER_REQUEST',
  ADD_CREDIT_TRANSFER_REQUEST: 'ADD_CREDIT_TRANSFER_REQUEST',
  APPROVE_CREDIT_TRANSFER: 'APPROVE_CREDIT_TRANSFER',
  DELETE_CREDIT_TRANSFER_REQUEST: 'DELETE_CREDIT_TRANSFER_REQUEST',
  ERROR_ADD_COMMENT_TO_CREDIT_TRANSFER: 'ERROR_ADD_COMMENT_TO_CREDIT_TRANSFER',
  ERROR_ADD_CREDIT_TRANSFER_REQUEST: 'ERROR_ADD_CREDIT_TRANSFER_REQUEST',
  ERROR_ADD_CREDIT_TRANSFER: 'ERROR_ADD_CREDIT_TRANSFER',
  ERROR_APPROVE_CREDIT_TRANSFER: 'ERROR_APPROVE_CREDIT_TRANSFER',
  ERROR_APPROVED_CREDIT_TRANSFERS: 'ERROR_APPROVED_CREDIT_TRANSFERS',
  ERROR_CREDIT_TRANSFER_REQUEST: 'ERROR_CREDIT_TRANSFER_REQUEST',
  ERROR_CREDIT_TRANSFERS_REQUEST: 'ERROR_CREDIT_TRANSFERS_REQUEST',
  ERROR_UPDATE_COMMENT_ON_CREDIT_TRANSFER: 'ERROR_UPDATE_COMMENT_ON_CREDIT_TRANSFER',
  GET_APPROVED_CREDIT_TRANSFERS_REQUEST: 'GET_APPROVED_CREDIT_TRANSFERS_REQUEST',
  GET_CREDIT_TRANSFER_REQUEST: 'GET_CREDIT_TRANSFER_REQUEST',
  GET_CREDIT_TRANSFERS_REQUEST: 'GET_CREDIT_TRANSFERS_REQUEST',
  PROCESS_APPROVED_CREDIT_TRANSFERS: 'PROCESS_APPROVED_CREDIT_TRANSFERS',
  RECEIVE_APPROVED_CREDIT_TRANSFERS_REQUEST: 'RECEIVE_APPROVED_CREDIT_TRANSFERS_REQUEST',
  RECEIVE_CREDIT_TRANSFER_REQUEST: 'RECEIVE_CREDIT_TRANSFER_REQUEST',
  RECEIVE_CREDIT_TRANSFERS_REQUEST: 'RECEIVE_CREDIT_TRANSFERS_REQUEST',
  SUCCESS_ADD_COMMENT_TO_CREDIT_TRANSFER: 'SUCCESS_ADD_COMMENT_TO_CREDIT_TRANSFER',
  SUCCESS_ADD_CREDIT_TRANSFER: 'SUCCESS_ADD_CREDIT_TRANSFER',
  SUCCESS_APPROVE_CREDIT_TRANSFER: 'SUCCESS_APPROVE_CREDIT_TRANSFER',
  SUCCESS_APPROVED_CREDIT_TRANSFERS: 'SUCCESS_APPROVED_CREDIT_TRANSFERS',
  SUCCESS_DELETE_CREDIT_TRANSFER: 'SUCCESS_DELETE_CREDIT_TRANSFER',
  SUCCESS_UPDATE_COMMENT_ON_CREDIT_TRANSFER: 'SUCCESS_UPDATE_COMMENT_ON_CREDIT_TRANSFER',
  SUCCESS_UPDATE_CREDIT_TRANSFER_REQUEST: 'SUCCESS_UPDATE_CREDIT_TRANSFER_REQUEST',
  UPDATE_COMMENT_ON_CREDIT_TRANSFER_REQUEST: 'UPDATE_COMMENT_ON_CREDIT_TRANSFER_REQUEST',
  UPDATE_CREDIT_TRANSFER_REQUEST: 'UPDATE_CREDIT_TRANSFER_REQUEST'
}
