export default {
  ERROR_FUEL_SUPPLIERS_REQUEST: 'ERROR_FUEL_SUPPLIERS_REQUEST',
  ERROR_ORGANIZATION_REQUEST: 'ERROR_ORGANIZATION_REQUEST',
  ERROR_ORGANIZATION_BALANCE_REQUEST: 'ERROR_ORGANIZATION_REQUEST',
  ERROR_ORGANIZATIONS_REQUEST: 'ERROR_ORGANIZATIONS_REQUEST',
  GET_FUEL_SUPPLIERS_REQUEST: 'GET_FUEL_SUPPLIERS_REQUEST',
  GET_MEMBERS_REQUEST: 'GET_MEMBERS_REQUEST',
  ERROR_MEMBERS_REQUEST: 'ERROR_MEMBERS_REQUEST',
  GET_ORGANIZATION_REQUEST: 'GET_ORGANIZATION_REQUEST',
  GET_ORGANIZATION_BALANCE_REQUEST: 'GET_ORGANIZATION_BALANCE_REQUEST',
  GET_ORGANIZATIONS_REQUEST: 'GET_ORGANIZATIONS_REQUEST',
  ADD_ORGANIZATION_REQUEST: 'ADD_ORGANIZATION_REQUEST',
  SUCCESS_ADD_ORGANIZATION_REQUEST: 'SUCCESS_ADD_ORGANIZATION_REQUEST',
  ERROR_ADD_ORGANIZATION_REQUEST: 'ERROR_ADD_ORGANIZATION_REQUEST',
  UPDATE_ORGANIZATION_REQUEST: 'UPDATE_ORGANIZATION_REQUEST',
  SUCCESS_UPDATE_ORGANIZATION_REQUEST: 'SUCCESS_UPDATE_ORGANIZATION_REQUEST',
  ERROR_UPDATE_ORGANIZATION_REQUEST: 'ERROR_UPDATE_ORGANIZATION_REQUEST',
  SEARCH_ORGANIZATIONS_REQUEST: 'SEARCH_ORGANIZATIONS_REQUEST',
  RECEIVE_FUEL_SUPPLIERS_REQUEST: 'RECEIVE_FUEL_SUPPLIERS_REQUEST',
  RECEIVE_MEMBERS_REQUEST: 'RECEIVE_MEMBERS_REQUEST',
  RECEIVE_ORGANIZATIONS_REQUEST: 'RECEIVE_ORGANIZATIONS_REQUEST',
  RECEIVE_ORGANIZATION_BALANCE_REQUEST: 'RECEIVE_ORGANIZATION_BALANCE_REQUEST'
}
