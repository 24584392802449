import React from 'react'

import CreditTradeHistoryTable from './CreditTradeHistoryTable'

const CreditTradeHistoryPage = props => (
  <div className="page_user_activity">
    <h1>User Activity</h1>
    <CreditTradeHistoryTable />
  </div>
)

export default CreditTradeHistoryPage
