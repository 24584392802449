const ORGANIZATION_STATUSES = {
  active: {
    id: 1,
    description: 'Active'
  },
  archived: {
    id: 2,
    description: 'Inactive'
  }
}

export default ORGANIZATION_STATUSES
