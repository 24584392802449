export default {
  CLEAR_ERROR_REQUEST: 'CLEAR_ERROR_REQUEST',
  ERROR_USER_REQUEST: 'ERROR_USER_REQUEST',
  GET_LOGGED_IN_USER: 'GET_LOGGED_IN_USER',
  SIGN_USER_OUT: 'SIGN_USER_OUT',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  RECEIVE_USER_REQUEST: 'RECEIVE_USER_REQUEST',
  USER_ADMIN: 'USER_ADMIN'
}
