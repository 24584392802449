export default {
  ERROR: 'ERROR',
  ERROR_LOGGED_IN_USER: 'ERROR_LOGGED_IN_USER',
  GET_LOGGED_IN_USER: 'GET_LOGGED_IN_USER',
  SIGN_USER_OUT: 'SIGN_USER_OUT',
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  RECEIVE_LOGGED_IN_USER: 'RECEIVE_LOGGED_IN_USER',
  RECEIVE_USER: 'RECEIVE_USER',
  SUCCESS: 'SUCCESS',
  CLEAR_ERROR: 'CLEAR_ERROR',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR'
}
