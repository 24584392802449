export default {
  ADD_FUEL_CODE: 'ADD_FUEL_CODE',
  DELETE_FUEL_CODE: 'DELETE_FUEL_CODE',
  GET_FUEL_CODE: 'GET_FUEL_CODE',
  GET_FUEL_CODES: 'GET_FUEL_CODES',
  GET_LATEST_FUEL_CODE: 'GET_LATEST_FUEL_CODE',
  ERROR: 'ERROR',
  RECEIVE_FUEL_CODE: 'RECEIVE_FUEL_CODE',
  RECEIVE_FUEL_CODES: 'RECEIVE_FUEL_CODES',
  RECEIVE_LATEST_FUEL_CODE: 'RECEIVE_LATEST_FUEL_CODE',
  SUCCESS: 'SUCCESS',
  UPDATE_FUEL_CODE: 'UPDATE_FUEL_CODE'
}
