export default {
  ERROR_NOTIFICATION_READ_STATUS_REQUEST: 'ERROR_NOTIFICATION_READ_STATUS_REQUEST',
  ERROR_NOTIFICATIONS_REQUEST: 'ERROR_NOTIFICATIONS_REQUEST',
  ERROR_SUBSCRIPTIONS_REQUEST: 'ERROR_SUBSCRIPTIONS_REQUEST',
  ERROR_UPDATE_SUBSCRIPTIONS_REQUEST: 'ERROR_UPDATE_SUBSCRIPTIONS_REQUEST',
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_COUNT_REQUEST: 'GET_NOTIFICATIONS_COUNT_REQUEST',
  GET_SUBSCRIPTIONS_REQUEST: 'GET_SUBSCRIPTIONS_REQUEST',
  RECEIVE_NOTIFICATIONS_REQUEST: 'RECEIVE_NOTIFICATIONS_REQUEST',
  RECEIVE_NOTIFICATIONS_COUNT_REQUEST: 'RECEIVE_NOTIFICATIONS_COUNT_REQUEST',
  RECEIVE_SUBSCRIPTIONS_REQUEST: 'RECEIVE_SUBSCRIPTIONS_REQUEST',
  SUCCESS_UPDATE_NOTIFICATION_READ_STATUS_REQUEST: 'SUCCESS_UPDATE_NOTIFICATION_READ_STATUS_REQUEST',
  SUCCESS_UPDATE_SUBSCRIPTIONS_REQUEST: 'SUCCESS_UPDATE_SUBSCRIPTIONS_REQUEST',
  UPDATE_NOTIFICATION_READ_STATUS_REQUEST: 'UPDATE_NOTIFICATION_READ_STATUS_REQUEST',
  UPDATE_SUBSCRIPTIONS_REQUEST: 'UPDATE_SUBSCRIPTIONS_REQUEST',
  MOUNT_NOTIFICATIONS_TABLE: 'MOUNT_NOTIFICATIONS_TABLE',
  UNMOUNT_NOTIFICATIONS_TABLE: 'UNMOUNT_NOTIFICATIONS_TABLE'
}
