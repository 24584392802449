export default {
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  ERROR_FUEL_SUPPLIERS: 'ERROR_FUEL_SUPPLIERS',
  ERROR: 'ERROR',
  GET_FUEL_SUPPLIERS: 'GET_FUEL_SUPPLIERS',
  GET_MEMBERS: 'GET_MEMBERS',
  ERROR_MEMBERS: 'ERROR_MEMBERS',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_ORGANIZATION_BALANCE: 'GET_ORGANIZATION_BALANCE',
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  RECEIVE_FUEL_SUPPLIERS: 'RECEIVE_FUEL_SUPPLIERS',
  RECEIVE_MEMBERS: 'RECEIVE_MEMBERS',
  RECEIVE_ORGANIZATION: 'RECEIVE_ORGANIZATION',
  RECEIVE_ORGANIZATION_BALANCE: 'RECEIVE_ORGANIZATION_BALANCE',
  RECEIVE_ORGANIZATIONS: 'RECEIVE_ORGANIZATIONS',
  RESET_ORGANIZATIONS_SEARCH: 'RESET_ORGANIZATIONS_SEARCH',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION'
}
