const API = '/roles'
const BASE_PATH = '/roles'

const ROLES = {
  API,
  DETAILS: `${BASE_PATH}/:id`,
  LIST: `${BASE_PATH}`
}

export default ROLES
